var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('breadcrumb',{attrs:{"items":_vm.breadcrumb}}),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"card_action",attrs:{"flat":""}},[_c('v-card-actions',[_c('v-row',{attrs:{"align-items":"center"}},[_c('v-col',{staticClass:"flex_end"},[_c('v-btn',{attrs:{"color":"secondary_dark","dark":"","depressed":"","tile":"","to":'/klient/edytuj/'+_vm.id}},[_vm._v(" Edytuj Klienta ")])],1)],1)],1)],1),_c('v-card',[_c('v-card-title',[_vm._v("Szczegóły o Kliencie")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('b',[_vm._v("Firma: ")]),_vm._v(_vm._s(_vm.data.full_name)),_c('br'),_c('b',[_vm._v("Nazwa Klienta: ")]),_vm._v(_vm._s(_vm.data.name)),_c('br'),_c('b',[_vm._v("NIP: ")]),_vm._v(_vm._s(_vm.data.nip)),_c('br'),_c('b',[_vm._v("e-mail: ")]),_vm._v(_vm._s(_vm.data.email)),_c('br'),_c('b',[_vm._v("Rola: ")]),_vm._v(_vm._s(_vm.data.role_name)),_c('br'),(_vm.data.recommending_user)?_c('b',[_vm._v("Osoba polecająca: ")]):_vm._e(),_vm._v(_vm._s(_vm.data.recommending_user)+" ")])],1)],1)],1),_c('v-card',{staticClass:"card_section"},[_c('v-card-title',[_vm._v("Osoby kontaktowe")]),_c('v-card-text',[_c('v-data-table',{staticClass:"classic_table",attrs:{"headers":_vm.headers,"items":_vm.data.contacts,"item-key":"name","footer-props":{
              itemsPerPageText : 'Elementów na stronie',
              itemsPerPageAllText : 'Wszystkie',
              itemsPerPageOptions : [15,45,90,-1],
            }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v("Brak danych")]},proxy:true},{key:"footer.page-text",fn:function(ref){
            var pageStart = ref.pageStart;
            var pageStop = ref.pageStop;
            var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+"-"+_vm._s(pageStop)+" z "+_vm._s(itemsLength)+" ")]}}])})],1)],1),_c('v-card',{staticClass:"card_section"},[_c('v-card-title',[_vm._v("Adres")]),_c('v-card-text',[_c('b',[_vm._v("Miasto: ")]),_vm._v(_vm._s(_vm.data.city)),_c('br'),_c('b',[_vm._v("Adres: ")]),_vm._v(_vm._s(_vm.data.address)+" "+_vm._s(_vm.data.zip_code)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }