<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">
          <v-card-actions>
            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  color="secondary_dark"
                  dark
                  depressed
                  tile
                  :to="'/klient/edytuj/'+id"
                >
                  Edytuj Klienta
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
        
        <v-card>
          <v-card-title>Szczegóły o Kliencie</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <b>Firma: </b>{{data.full_name}}<br>
                <b>Nazwa Klienta: </b>{{data.name}}<br>
                <b>NIP: </b>{{data.nip}}<br>
                <b>e-mail: </b>{{data.email}}<br>
                <b>Rola: </b>{{data.role_name}}<br>
                <b v-if="data.recommending_user">Osoba polecająca: </b>{{data.recommending_user}}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="card_section">
          <v-card-title>Osoby kontaktowe</v-card-title>
          <v-card-text>

            <v-data-table
              class="classic_table"
              :headers="headers"
              :items="data.contacts"
              item-key="name"
              :footer-props="{
                itemsPerPageText : 'Elementów na stronie',
                itemsPerPageAllText : 'Wszystkie',
                itemsPerPageOptions : [15,45,90,-1],
              }"
            >
              <template v-slot:no-data>Brak danych</template>
              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                {{ pageStart }}-{{ pageStop }} z {{ itemsLength}}
              </template>
            </v-data-table>

          </v-card-text>
        </v-card>

        <v-card class="card_section">
          <v-card-title>Adres</v-card-title>
          <v-card-text>
            <b>Miasto: </b>{{data.city}}<br>
            <b>Adres: </b>{{data.address}} {{data.zip_code}}
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>
    
    
  </div>
</template>

<script>
// #TODO sprawdzic czy zimny poprawnie przesyla data.contacts poniewaz przesylal contat
export default {
  data: () => ({
    id: 0,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista Klientów',
        disabled: false,
        to: '/klienci',
      },
      {
        text: 'Podgląd Klienta',
        disabled: true,
        to: '/klient',
      },
    ],

    headers: [
      { text: 'Osoba kontaktowa', value: 'name' },
      { text: 'Numer telefonu', value: 'tel' },
      { text: 'E-mail', value: 'email' },
      { text: 'Stanowisko', value: 'position' }
    ],

    attached_files: 0,
    number_invoice: '68/2/2021',
    data: {
      address: 'Ulica 21',
      contact_person: 'Imię Nazwisko',
      city: 'Rzeszów',
      email: 'mail@mail.pl',
      full_name: 'Andrzej Maślak z ZOO',
      nip: '123456789',
      recommending_user: 'Andrzej Maślak z ZOO',
      zip_code: '35-000'
    },
    
    search: ''
  }),
  methods: {

  },
  mounted(){
    this.id = this.$route.params.id;
    // console.log(this.id);

    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/customer/'+this.id, data: {}, method: 'GET' })
      .then(resp => {
        this.data = resp.data.user;
        // console.log(resp.data);
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.card_section {
  margin-top: 25px;
}
.checkbox {
  margin-top: 0;
}
</style>